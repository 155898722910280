import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, NavLink } from 'react-router-dom';
import { login } from '../../redux/authentication/actionCreator';
import { Button } from '../../components/buttons/buttons';
import { getCartCount } from '../../redux/cartCount/actionCreator';
import { headerSearchAction } from '../../redux/headerSearch/actionCreator';
import axios from 'axios'
import './style.css';
import MapBoxComponent from './MapBoxComponent.js';

function MapLists() {

    const dispatch = useDispatch();

    const [placeArray, setPlaceArray] = useState([])
    
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/organization-list/not`)
            .then((response) => {
                let placeArr = []
                console.log("placeArray", response.data)
                response.data.forEach(element => {
                    if (element.address_coordinate != null) {
                        element.address_coordinate = JSON.parse(element.address_coordinate);
                        console.log("element.address_coordinate :", element.address_coordinate);
                        var image_array = element.logo != null && element.logo.length > 0 ? JSON.parse(element.logo) : [];
                        console.log("image_array : ", image_array);
                        var image_url = 'https://a0.muscache.com/im/pictures/miso/Hosting-867054237184940077/original/9787d38f-2473-4a2e-9271-2a2a5f40891e.jpeg?im_w=720'
                        if (image_array && image_array.length > 0) {
                            image_url = process.env.REACT_APP_LOGO_URL + image_array[0].url
                        }
                        else {
                            image_url = 'https://a0.muscache.com/im/pictures/miso/Hosting-867054237184940077/original/9787d38f-2473-4a2e-9271-2a2a5f40891e.jpeg?im_w=720'
                        }
                        var place_details_url = 'org/details/' + element.name.toLowerCase().replace(/ /g, '-') + '-' + element.id + '-0';

                        placeArr.push({
                            id: element.id,
                            name: element.name,
                            latitude: element.address_coordinate[1],
                            longitude: element.address_coordinate[0],
                            organization_address: element.address,
                            organization_desciption: element.description != null ? element.description.slice(0, 200) + ' ...' : "",
                            image_url: image_url,
                            organization_category: element.organization_category,
                            organization_details_url: place_details_url
                        })
                    }

                });
                setPlaceArray(placeArr)
                console.log(" after placeArr :", placeArr)

            }).catch((error) => {
                console.log("Error", error)
            })

    }, []);

    const history = useHistory();

    const { isFbAuthenticate } = useSelector(state => {//isLoading,
        return {
            //isLoading: state.firebaseAuth.loading,
            isFbAuthenticate: state.fb.auth.uid,
        };
    });

    useEffect(() => {
        sessionStorage.removeItem('remove_cart');
        if (getCartCount) {
            dispatch(getCartCount());
        }
        if (headerSearchAction) {
            dispatch(headerSearchAction(false));
        }
    }, [isFbAuthenticate,  dispatch]);


    return (
        <>
            <Row>
                <Col style={{ top: 2 }} xxl={24} xl={24} lg={24} md={24} xs={24}>
                    {
                        placeArray.length > 0 && <MapBoxComponent places={placeArray} />
                    }
                </Col>
            </Row>

        </>
    );
}

export default MapLists;    
