import React, { useEffect, useState } from 'react';
import { Modal } from '../components/modals/antd-modals';
import propTypes from 'prop-types';
//import { useSelector } from 'react-redux';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import Swiper from 'react-id-swiper';
import { TestimonialStyleWrapper } from './pages/style';
import axios from 'axios';

import 'swiper/scss';
import 'swiper/scss/pagination';
import './orgModalstyle.css';
import Cookies from 'js-cookie';

SwiperCore.use([Navigation, Pagination]);

function OrganizationModal({ visible, onCancel }) {
  

  const [organizationData, setOrganizationData] = useState([]);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
  });


  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/organization-list/future`)
      .then((response) => {
        setOrganizationData(response.data);
      })
      .catch((error) => {
        console.log("Error", error)
      })

    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);

  const handleCancel = () => {
    //var date = new Date();
    //date.setTime(date.getTime() + (30 * 1000));
    //Cookies.set('advertisement', true, { expires: date });
    sessionStorage.setItem('advertisement', true);
    onCancel();
  };

  const paramsThree = {
    containerClass: 'swiper',
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    slidesPerView: 1,
    centeredSlides: true,
    loop: true,
  };

  return (
    <>
      <React.Fragment>
        <Modal
          type={state.modalType}
          visible={state.visible}
          footer={[null]}
          onCancel={handleCancel}
          //width={'65%'}
          className="org-modal"
        >
          <TestimonialStyleWrapper>
            <div className="testimonial-block theme-4">
              <Swiper {...paramsThree}>
                {organizationData && organizationData.map((org, index) => {
                  let total_count=organizationData.length;
                  let logo_array = null;
                  let video_link = org.video_link;
                  if (org.logo) {
                    logo_array = JSON.parse(org.logo);
                    //console.log('logo_array',logo_array[0].url);
                  }
                  if (video_link !== null && video_link.includes("youtube") === true) {
                    video_link = org.video_link;
                  } else {
                    video_link = null;
                  }
                  return (
                    <div key={index + 1} className="testimonial-block__single">
                      <p style={{float:'left'}}>({index + 1}/<b>{total_count}</b>)</p>
                      <div className="testimonial-block__inner">                      
                        <div className="testimonial-block__author">
                          {(logo_array != null && logo_array[0] && <img src={process.env.REACT_APP_LOGO_URL + logo_array[0].url} alt="Organization Logo" />)}
                          <h2 className="client-name" style={{ fontWeight: 700 }}>{org.name}</h2>
                          
                        </div>
                        {/* <div className="header_desktop" style={{ width: '100%', overflow: 'hidden' }}>
                          <div style={{ width: '30%', float: 'left', maxHeight: '80px', maxWidth: '80px',marginRight:'20px' }}> {(logo_array != null && logo_array[0] && <img src={process.env.REACT_APP_LOGO_URL + logo_array[0].url} style={{ width: '100%',marginLeft:'72px' ,paddingRight:'10px' }} alt="Organization Logo" />)}</div>
                          <div style={{ width: '70%' }}>
                            <h1  style={{ fontWeight: 400, paddingLeft: '23px', paddingTop: '20px' }}>{org.name}</h1>
                          </div>
                        </div> */}
                        <div className="testimonial-block__review">
                          {video_link && <iframe align="center" width="87%" height="300" src={video_link} id="youtube_video" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title='Organization Video' className='youtube_video'></iframe>}
                          {!video_link && <div align="center" width="90%" style={{ height: '280px', paddingTop: '60px', fontSize: '30px' }}><i class="fa fa-video-camera" aria-hidden="true" style={{ fontSize: '50px' }}></i> <br />Video not available</div>}
                        </div>
                        <div className="author-info" style={{ textAlign: 'left' }}>{org.description}</div>
                      </div>
                    </div>
                  );
                }
                )}
              </Swiper>
            </div>
          </TestimonialStyleWrapper>
        </Modal>
      </React.Fragment>
    </>
  );
}

OrganizationModal.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired
};


export default OrganizationModal;

