import React, { Suspense, lazy } from 'react';
import { Spin } from 'antd';
import { Switch, Route, useRouteMatch } from 'react-router-dom';

import Dashboard from './dashboard';
import Users from './users';
import withAdminLayout from '../../layout/withAdminLayout';
import NewsEventList from '../../container/pages/feed/NewsEventList';
import EventsListDataTable from '../../container/pages/event/EventsListDataTable';
import EventDataList from '../../container/pages/event/EventDataList';
import InviteOrganization from '../../container/invite/InviteOrganization';
import InitiativeListDataTable from '../../container/pages/fundInitiative/InitiativeListDataTable';
import MyPlansDataTable from '../../container/pages/userPlans/MyPlansDataTable';
import ContactListDataTable from '../../container/pages/Contact/ContactListDataTable';
import EventDetails from '../../container/home/EventDetails';


const Projects = lazy(() => import('./projects'));
const Myprofile = lazy(() => import('../../container/profile/myProfile/Index'));
const ContentPage = lazy(() => import('../../container/settings/ContentPage'));
const EmailCampaigning = lazy(() => import('../../container/settings/EmailCampaigning'));
const CommonCampaign = lazy(() => import('../../container/settings/CommonCampaign'));
const ManageSettings = lazy(() => import('../../container/settings/ManageSettings'));
const DirectDonationDetails = lazy(() => import('../../container/ecommerce/product/ProductDetails'));
const Cart = lazy(() => import('../../container/ecommerce/Cart'));
const CampaigningListDataTable = lazy(() => import('../../container/pages/campaigning/CampaigningListDataTable'));
const FeedsListDataTable = lazy(() => import('../../container/pages/feed/FeedsListDataTable'));
const PrivacyPolicy = lazy(() => import('../../container/pages/PrivacyPolicy'));
const MapLists = lazy(() => import('../../container/home/MapLists'));
const DashboardActivity = lazy(() => import('../../container/dashboard/index'));


function Admin() {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Suspense
        fallback={
          <div className="spin">
            <Spin />
          </div>
        }
      >
        <Route path={path} component={Dashboard} />
        <Route path={`/dashboard`} component={DashboardActivity} />
        <Route path={`/project/view/list`} component={Projects} />        
        <Route path={`/map-listings`} component={MapLists} />        
        <Route path="/news" component={NewsEventList} />
        <Route path="/events" component={EventDataList} />
        <Route path={`/cart`} component={Cart} />
        <Route path={`/users`} component={Users} />
        <Route path={`/org/donation/:name/:id`} component={DirectDonationDetails} />
        <Route path={`/org`} component={Projects} />
        <Route path={`/admin`} component={Myprofile} />
        <Route path={`/profile/myProfile`} component={Myprofile} />
        <Route path={`/contents`} component={ContentPage} />
        <Route path={`/manage-campaigning`} component={CommonCampaign} />
        <Route path={`/list-campaigning`} component={CampaigningListDataTable} />
        <Route path={`/list-feeds`} component={FeedsListDataTable} />
        <Route path={`/list-events`} component={EventsListDataTable} />
        <Route path={`/event-details/:id`} component={EventDetails} />
        
        <Route path={`/list-fund-initiative`} component={InitiativeListDataTable} />
        <Route path={`/plans-list`} component={MyPlansDataTable} />
        <Route path={`/list-contact-us`} component={ContactListDataTable} />
        <Route path={`/manage-settings`} component={ManageSettings} />
        <Route path={`/invite-organization`} component={InviteOrganization} />
        <Route path={`/privacy-policy`} component={PrivacyPolicy} />
      </Suspense>
    </Switch>
  );
}

export default withAdminLayout(Admin);