import { Input, Col, Row, Space, Spin,Button } from 'antd'

import { Link, NavLink, useHistory, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
//import withoutLoginLayout from '../../../../src/layout/withoutLoginLayout';
const NewsEventList = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [newsData, setNewsData] = useState([]);
    const [searchData, setsearchData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/get-feeds/null/all`)
            .then(function (response) {
                console.log("response", response)
                if (response.data.length > 0) {
                    setNewsData(response.data)
                    setsearchData(response.data)
                    setLoading(false);
                }
            })
            .catch(function (error) {
                console.log(error);
            })
    }, []);

    const redirectURL = (url) => {
        //history.push(url);
        window.open(url, '_blank');
    };

    const searchRecords = (searchText) => {
        console.log("searchText:", searchText);
        // Filter newsData based on searchText
        const filteredData = newsData.filter(item =>
            item.title.toLowerCase().includes(searchText.toLowerCase()) ||
            item.description.toLowerCase().includes(searchText.toLowerCase()) ||
            item.name.toLowerCase().includes(searchText.toLowerCase()) ||
            (item.location && item.location.toLowerCase().includes(searchText.toLowerCase())) ||
            item.link.toLowerCase().includes(searchText.toLowerCase())
        );

        console.log("Filtered Data:", filteredData);
        // Update the searchData state with filtered data
        setsearchData(filteredData);
    };

    return (
        <div style={{
            width: '100%',
        }}>

            <div>
                <div style={{ marginTop: 10, paddingBottom: 60 }}>
                    <Row style={{ marginRight: 10, marginLeft: 10 }} className='set-homepage-content'>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                        <Col xs={24} sm={24} md={20} lg={20}>
                            <Row justify="center" style={{ margin: '10px 0' }}>
                                <Col span={24} md={12}>
                                    <p style={{ fontFamily: "Melodrama", fontSize: 45, marginBottom: 25 }} >News</p>
                                </Col>
                                <Col span={24} md={12}>
                                    <Input type='text' placeholder='Search here' onChange={(e) => { searchRecords(e.target.value) }} />
                                </Col>
                            </Row>
                            {
                                [...searchData].length > 0 ?
                                    <div>
                                        <Row>
                                            {
                                                [...searchData].map((item) => {
                                                    if (item) {
                                                        let height = 25;
                                                        let url_link = item.link;
                                                        let imagesURL = item.image;
                                                        let logo = '';
                                                        if (item.logo != null) {
                                                            logo = process.env.REACT_APP_LOGO_URL + item.logo
                                                        }
                                                        return (
                                                            imagesURL &&
                                                            <Row style={{ width: '100%', marginBottom: 20 }}>
                                                                <Col xs={24} sm={24} md={10} lg={13} xl={13} className='add-padding-desktop' style={{ textAlign: 'right', padding: '30px 2px' }}>
                                                                    <>
                                                                        <a href={url_link} style={{ background: 'white', justifyContent: 'right', display: 'flex', alignItems: 'right' }} target='_blank'>
                                                                            <img
                                                                                src={imagesURL}
                                                                                alt="Event Image"
                                                                                style={{
                                                                                    borderRadius: 8,
                                                                                    cursor: 'pointer',
                                                                                    width: '95%',
                                                                                    maxHeight: '300px',
                                                                                    minHeight: '250px'
                                                                                }}
                                                                            />
                                                                        </a>
                                                                    </>
                                                                </Col>
                                                                <Col xs={24} sm={24} md={14} lg={11} xl={11} style={{ textAlign: 'left' }}>
                                                                    <>
                                                                        <div class="five columns">
                                                                            <section class="entry-content">
                                                                                <h1 class="entry-title">
                                                                                    <a href={url_link} rel="bookmark" target='_blank'>
                                                                                        <span>{item.title}</span>
                                                                                    </a>
                                                                                </h1>
                                                                                <div class="entry-meta">
                                                                                    {/* by <span class="entry-author">{item.name}</span>
                                                                                    •*/}
                                                                                    <span class="entry-date">{item.convert_date} </span>
                                                                                </div>
                                                                                <div class="entry-excerpt" style={{ display: 'block' }} dangerouslySetInnerHTML={{ __html: item.description != "" ? item.description.substring(0, 300) + " ..." : "" }}>

                                                                                </div>
                                                                                <Button size='default' type="primary" onClick={() => {
                                                                                    redirectURL(url_link)
                                                                                }} style={{ cursor: 'pointer', marginTop: 5, height: '40px !important' }}>
                                                                                    <p style={{ fontFamily: 'Arial', fontSize: 13, margin: 0, fontWeight: 600, lineHeight: '24px', letterSpacing: ' 0em', textAlign: 'left' }}>Keep reading</p>
                                                                                </Button>
                                                                            </section>
                                                                        </div>
                                                                    </>
                                                                </Col>
                                                            </Row>
                                                        )


                                                    }
                                                })
                                            }
                                        </Row>
                                    </div>
                                    :
                                    <Row style={{ justifyContent: 'center' }}>
                                        {/* <p style={{ fontFamily: "Melodrama", fontSize: 45 }} align="center">Comming Soon</p> */}
                                    </Row>
                            }

                        </Col>
                        <Col xs={0} sm={0} md={2} lg={2}></Col>
                    </Row>
                </div>

            </div>
        </div>
        
    )
}

export default NewsEventList;
//export default withoutLoginLayout(NewsEventList);

