import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';



const { TextArea } = Input;

const CreateInitiative = ({ visible, onCancel }) => {

  const dispatch = useDispatch();

  const isFbAuthenticate = useSelector(state => {

    return {
      isFbAuthenticate: state.fb.auth.uid,
    }
  });

  


  const [form] = Form.useForm();
  const formData = new FormData();
  //const [is_framed, setIsFramed] = useState(false);

  const [state, setState] = useState({
    visible,
    modalType: 'primary',
    checked: [],
    title: '',
    description: '',
    link: '',
    image: '',
    status: '',
    isLoading: false
  });


  const [image, setImage] = useState('');

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 18 },
  };

  useEffect(() => {
    let unmounted = false;
    if (!unmounted) {
      setState({
        visible,
      });
    }
    return () => {
      unmounted = true;
    };
  }, [visible]);




  const [buttonDisable, setButton] = useState(false);
  const [loading, setLoading] = useState(false);
  


  const fileobj = [];


  const handleOk = (values) => {
    

    const start_date = moment(values.start_date).format('YYYY-MM-DD');
    const end_date = moment(values.end_date).format('YYYY-MM-DD');

    let post_data = {
      uid: isFbAuthenticate.isFbAuthenticate,
      ...values,
      start_date: start_date,
      end_date: end_date,
    };

    console.log(values);
    
    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });


    console.log(post_data);

    const formData = new FormData();
    if (image) {
      formData.append('files', image);
    }

    formData.append('data', JSON.stringify(post_data))

    setButton(true);
    setLoading(true);

    setState({
      ...state,
      isLoading: true,
    });

    axios.post(`${process.env.REACT_APP_API_URL}/create-fund-initiative`, formData)
      .then((response) => {
        if (response.data.status == true) {
          alert("The Fund initiative created successfully.");
          //window.location.reload();
          onCancel();
        } else {
          setButton(false);
          setLoading(false);
          alert("Uanble to update the fund initiative");
        }
      })
      .catch((error) => {
        console.log("Error :", error)
      })

  };




  const handleCancel = () => {
    onCancel();
  };

  const handleImageChange = (e) => {
    // console.log('this is e')
    console.log("e.target.files", e.target.files);
    //console.log(e.target.name)
    if (e.target.files.length) {
      for (var i = 0; i < e.target.files.length; i++) {
        if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
          message.error('Invalid image format', process.env.REACT_APP_NOTIFICATION_DELAY)
          return false;
        }
      }
      setImage(e.target.files[0]);
    } else {
      setImage("");
    }
  }




  return (

    <>

      <Modal
        type={state.modalType}
        title="Create Fund initiative"
        visible={state.visible}
        width={1000}
        backdrop="static"
        footer={[null]}
        onCancel={handleCancel}
      >
        <div className="project-modal">
          <>
            <FormValidationWrap>
              <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">
                <Form.Item name="title" label="Title"
                  rules={[{ required: true, message: 'Please input title' }]}

                >
                  <Input.TextArea rows={2} placeholder="" name="title" />
                </Form.Item>

                <Form.Item name="description" label="Description"
                  rules={[{ required: true, message: 'Please input description' }]}
                >
                  <TextArea rows={3} type='text' name='description' />
                </Form.Item>

                <Form.Item name="image" label="Image URL"
                >
                  <Input type='file' name='image' onChange={handleImageChange} />
                </Form.Item>


                <Row gutter={15}>
                  <Col md={12} xs={24} sm={12} >
                    <Form.Item name="start_date" label="Start Date"
                      rules={[{ required: true, message: 'Please input start date' }]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>
                  </Col>
                  <Col md={12} xs={24} sm={12} >
                  <Form.Item name="end_date" label="End Date"
                      rules={[{ required: true, message: 'Please input end date' }]}
                    >
                      <DatePicker format={dateFormat} />
                    </Form.Item>

                  </Col>
                </Row>

                <Form.Item name="tags" label="Tags"
                  rules={[{ required: true, message: 'Please input tags' }]}
                >
                  <Input placeholder="tags" name="tags" />
                </Form.Item>

                <Form.Item>

                  <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                    Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                  </Button>
                  {' '}
                  <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                    Cancel
                  </Button>
                </Form.Item>
              </Form>
            </FormValidationWrap>
          </>

        </div>
      </Modal>
    </>
  );
};

CreateInitiative.propTypes = {
  visible: propTypes.bool.isRequired,
  onCancel: propTypes.func.isRequired,
};

export default CreateInitiative;