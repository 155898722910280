import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select, Col, Row, Upload, TimePicker, message, Button, DatePicker } from 'antd';
import propTypes from 'prop-types';
import { Modal } from '../../../components/modals/antd-modals';
import { FormValidationWrap } from '../../forms/overview/Style';
import styled from "styled-components";
import moment from 'moment';
import axios from 'axios';
import useInput from '../../profile/authentication/overview/useInput';


const { Option } = Select;

const dateFormat = 'MM/DD/YYYY';
const format = 'h:mm a';


const { TextArea } = Input;

const EditInitiative = ({ visible, Id, onCancel, editData }) => {

    const dispatch = useDispatch();

    const { isAuthenticate } = useSelector(state => {
        return {
            isAuthenticate: state.fb.auth.uid
        };
    });

    //let address = useInput();
    let address = useInput(editData != null ? editData.address : '');
    const [form] = Form.useForm();
    const formData = new FormData();
    //const [is_framed, setIsFramed] = useState(false);
    //const [editData, setEditData] = useState(null);
    const [ShowForm, setShowForm] = useState(false);

    const [state, setState] = useState({
        visible,
        modalType: 'primary',
        checked: [],
        title: '',
        description: '',
        link: '',
        image: '',
        status: '',
        isLoading: false
    });

    const handleDropdownChange = (value, name) => {
        // return;
        setState({
            ...state,
            [name]: value,
        });
    }


    useEffect(() => {
        sessionStorage.removeItem('tab-index');
        if (editData) {
            getElementData(editData);
        }

    }, [Id]);
    const getElementData = async (editData) => {
        // console.log("editdata",editData)
        try {
            setStartDate(editData.start_date);
            setEndDate(editData.end_date);
            setShowForm(true);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }


    const [image, setImage] = useState('');

    const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
    };

    useEffect(() => {
        let unmounted = false;
        if (!unmounted) {
            setState({
                visible,
            });
        }
        return () => {
            unmounted = true;
        };
    }, [visible]);




    const [buttonDisable, setButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [start_date, setStartDate] = useState(null);
    const [end_date, setEndDate] = useState(null);


    const fileobj = [];


    const handleOk = (values) => {

        const start_date_latest = moment(values.start_date).format('YYYY-MM-DD');
        const end_date_latest = moment(values.end_date).format('YYYY-MM-DD');
        let post_data = {
            initiative_id: editData.initiative_id,
            ...values,
            old_image: editData.images,
            start_date: start_date_latest,
            end_date: end_date_latest,
            status: editData.status,
        };
        if (isAuthenticate == process.env.REACT_APP_ADMIN_UID) {
            post_data = {
                initiative_id: editData.initiative_id,
                ...values,
                old_image: editData.images,
                start_date: start_date_latest,
                end_date: end_date_latest,
                status: values.status
            };
        }
        //console.log(values);
        //console.log(start_time1, end_time1);
        setButton(true);
        setLoading(true);

        setState({
            ...state,
            isLoading: true,
        });
        console.log(post_data);
        //return false;

        const formData = new FormData();
        if (image) {
            formData.append('files', image);
        }
        formData.append('data', JSON.stringify(post_data))
        setButton(true);
        setLoading(true);
        setState({
            ...state,
            isLoading: true,
        });

        axios.post(`${process.env.REACT_APP_API_URL}/update-fund-initiative`, formData)
            .then((response) => {
                if (response.data.status == true) {
                    alert("The fund initiative update successfully.");
                    window.location.reload();
                    //onCancel();
                } else {
                    setButton(false);
                    setLoading(false);
                    alert("Uanble to update the fund initiative");
                }
            })
            .catch((error) => {
                console.log("Error :", error)
            })

    };

    const handleCancel = () => {
        onCancel();
    };

    const handleImageChange = (e) => {
        // console.log('this is e')
        console.log("e.target.files", e.target.files);
        //console.log(e.target.name)
        if (e.target.files.length) {
            for (var i = 0; i < e.target.files.length; i++) {
                if (!e.target.files[i].name.match(/.(jpg|jpeg|png|bmp)$/i)) {
                    message.error('Invalid image format', process.env.REACT_APP_NOTIFICATION_DELAY)
                    return false;
                }
            }
            setImage(e.target.files[0]);
        } else {
            setImage("");
        }
    }

    return (
        <>
            <Modal
                type={state.modalType}
                title="Edit Fund initiative"
                visible={state.visible}
                width={1000}
                backdrop="static"
                footer={[null]}
                onCancel={handleCancel}
            >
                <div className="project-modal">
                    <>
                        <FormValidationWrap>
                            {ShowForm == true &&
                                <>
                                    <Form form={form} name="sDash_validation-form" layout="vertical" onFinish={handleOk} encType="multipart/form-data">

                                        <Form.Item name="title" label="Title"
                                            rules={[{ required: true, message: 'Please input title' }]}
                                            initialValue={editData ? editData.title : ''}
                                        >
                                            <Input.TextArea rows={2} placeholder="" name="title" />
                                        </Form.Item>

                                        <Form.Item name="description" label="Description"
                                            rules={[{ required: true, message: 'Please input description' }]}
                                            initialValue={editData ? editData.description : ''}
                                        >
                                            <TextArea rows={3} type='text' name='description' />
                                        </Form.Item>

                                        <Form.Item name="image" label="Image URL"
                                        >
                                            <Input type='file' name='image' onChange={handleImageChange} />
                                        </Form.Item>

                                        <Row gutter={15}>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="start_date" label="Start Date"
                                                    rules={[{ required: true, message: 'Please input start date' }]}
                                                    initialValue={editData && editData.start_date ? moment(editData.start_date) : null}
                                                >
                                                    <DatePicker format={dateFormat} />
                                                </Form.Item>
                                            </Col>
                                            <Col md={12} xs={24} sm={12} >
                                                <Form.Item name="end_date" label="End Date"
                                                    rules={[{ required: true, message: 'Please input end date' }]}
                                                    initialValue={editData && editData.end_date ? moment(editData.end_date) : null}
                                                >
                                                    <DatePicker format={dateFormat} />
                                                </Form.Item>
                                            </Col>
                                        </Row>


                                        <Form.Item name="tags" label="Tags"
                                            rules={[{ required: true, message: 'Please input tags' }]}
                                            initialValue={editData ? editData.tags : ''}
                                        >
                                            <Input placeholder="tags" name="tags" />
                                        </Form.Item>

                                        {isAuthenticate === process.env.REACT_APP_ADMIN_UID ?
                                            <>
                                                <Form.Item name="status" label="Status"
                                                    rules={[{ required: true, message: 'Please input status' }]}
                                                    initialValue={Number(JSON.parse(editData.status))}
                                                >
                                                    <Select
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        size="large" className="sDash_fullwidth-select" name="status" placeholder="Select from dropdown"
                                                        onChange={(value) => handleDropdownChange(value, 'status')}
                                                    >
                                                        <Option key={1} value={1}>Active</Option>
                                                        <Option key={0} value={0}>Inactive</Option>
                                                    </Select>
                                                </Form.Item>
                                            </>
                                            : ""}


                                        <Form.Item>

                                            <Button type="primary" htmlType="submit" disabled={buttonDisable}>
                                                Submit {loading && (<i className='fa fa-spinner fa-spin' style={{ fontSize: 18, marginLeft: 5 }} />)}
                                            </Button>
                                            {' '}
                                            <Button size="default" type="white" key="back" outlined onClick={handleCancel}>
                                                Cancel
                                            </Button>
                                        </Form.Item>
                                    </Form>
                                </>}
                        </FormValidationWrap>
                    </>

                </div>
            </Modal>
        </>
    );
};

EditInitiative.propTypes = {
    visible: propTypes.bool.isRequired,
    onCancel: propTypes.func.isRequired,
};

export default EditInitiative;


